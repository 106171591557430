import { mdiHelp, mdiTable } from '@mdi/js'
import PERMISSIONS from '@core/constants/PermissionConstants'

export default [
  {
    subheader: 'AI',
    permission: PERMISSIONS.AI_EXPORTS_READ,
  },
  {
    title: 'Сообщения Vika ✨',
    icon: mdiHelp,
    to: 'ai-help-bot-messages',
    permission: PERMISSIONS.AI_EXPORTS_READ,
  },
  {
    title: 'Датасет классификаций ✨',
    icon: mdiTable,
    to: 'ai-text-dataset',
    permission: PERMISSIONS.AI_EXPORTS_READ,
  },
  {
    title: 'Выгрузка Youtube',
    icon: mdiTable,
    to: 'ai-youtube-exports',
    permission: PERMISSIONS.AI_EXPORTS_READ,
  },
]
